/* Content-1:Start */
.box {
  height: fit-content;
  border-radius: 20px;
  padding: 10px;
  background: #ededed;
}


.content {
  margin: 15px 2px;
}

.image img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: rgba(240, 46, 69, 0.4) 0px 5px, rgba(240, 46, 82, 0.3) 0px 10px, rgba(240, 46, 49, 0.2) 0px 15px, rgba(240, 46, 49, 0.1) 0px 20px, rgba(240, 46, 88, 0.05) 0px 25px; 
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
}

.level {
  font-size: 0.7em;
  color: rgba(107, 193, 81, 0.5);
  width: 80px;
  padding: 3px;
  font-weight: bolder;
  letter-spacing: 1px;
  display: block;
  /* margin: 0px auto 10px; */
}

.name {
  font-size: 1.25em;
  font-weight: bolder;
  letter-spacing: 1px;
}

.job_title {
  font-size: 1.25em;
  font-weight: bolder;
  color: gray;
  margin-top: -2px;
}

.job_discription {
  font-size: 1.0em;
  color: gray;
  /* margin: 8px 20px; */
}

.icons {
  margin: 0px 30px;
  font-size: 1.5em;
  display: flex;
  justify-content: space-around;
}

.icons button {
  width: fit-content;
  height: fit-content;
  border: none;
  font-size: 1em;
}

ion-icon:hover {
  color: #58a497;
  transition: 0.5s;
}

/*  */
/* Content-1:End */


/* Responsiveness:Start */
@media screen and (max-width: 480px) {
  .box {
    width: 100vw;
    border-radius: 0px;
  }

  .content2 {
    padding: 0px 20px;
  }

  .content2 img {
    width: 60px;
    height: 60px;
  }

  .onMobileUpdate {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

/* Responsiveness:End */