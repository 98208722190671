
.dateRangePicker {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.dateRangePicker label {
  margin-bottom: 5px;
}

.dateInput {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.calendarContainer {
  margin-top: 10px;
}

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.day {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  background-color: #f2f2f2;
  border-radius: 4px;
  cursor: pointer;
}

.day:hover {
  background-color: #e0e0e0;
}

.day.active {
  background-color: #3366cc;
  color: #fff;
}

.day.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
