@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import "react-loading-skeleton/dist/skeleton.css";
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap");

* {
  font-family: "Baloo 2", sans-serif;
}

/* 
---------------------------------------------
reset
--------------------------------------------- 
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
div pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
figure,
header,
nav,
section,
article,
aside,
footer,
figcaption {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer,
.grid-item {
  width: 50%;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.grid-item {
  float: left;
}

.grid-item img {
  display: block;
  max-width: 100%;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.clearfix {
  display: inline-block;
}

html[xmlns] .clearfix {
  display: block;
}

* html .clearfix {
  height: 1%;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

header,
nav,
section,
article,
aside,
footer,
hgroup {
  display: block;
}

* {
  box-sizing: border-box;
}

html,
body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  /* background-color: #fff; */
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 0px;
}

ul {
  margin-bottom: 0px;
}

p {
  font-size: 15px;
  line-height: 30px;
  color: #2a2a2a;
}

img {
  margin-bottom: -3px;
  width: 100%;
  overflow: hidden;
}

/* 
---------------------------------------------
global styles
--------------------------------------------- 
*/
html,
body {
  background: #fff;
  font-family: "Poppins", sans-serif;
}

::selection {
  background: #03a4ed;
  color: #fff;
}

::-moz-selection {
  background: #03a4ed;
  color: #fff;
}

/*---------------------
  About US
-----------------------*/

/*---------------------
  Contact Section
-----------------------*/

.contact-section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.contact-text h2 {
  font-size: 44px;
  color: #19191a;
  margin-bottom: 24px;
}

.contact-text p {
  color: #707079;
}

.contact-text table tbody tr td {
  font-size: 16px;
  color: #19191a;
  line-height: 36px;
  font-weight: 500;
}

.contact-text table tbody tr td.c-o {
  color: #707079;
  width: 75px;
}

.contact-form input {
  width: 100%;
  height: 50px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 25px;
  margin-bottom: 28px;
}

.contact-form input::-webkit-input-placeholder {
  color: #aaaab3;
}

.contact-form input::-moz-placeholder {
  color: #aaaab3;
}

.contact-form input:-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form input::-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form input::placeholder {
  color: #aaaab3;
}

.contact-form textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #e1e1e1;
  font-size: 16px;
  color: #aaaab3;
  padding-left: 25px;
  padding-top: 12px;
  margin-bottom: 33px;
  resize: none;
}

.contact-form textarea::-webkit-input-placeholder {
  color: #aaaab3;
}

.contact-form textarea::-moz-placeholder {
  color: #aaaab3;
}

.contact-form textarea:-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form textarea::-ms-input-placeholder {
  color: #aaaab3;
}

.contact-form textarea::placeholder {
  color: #aaaab3;
}

.contact-form button {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 2px;
  background: #dfa974;
  border: none;
  padding: 14px 34px 13px;
  display: inline-block;
}

.map {
  height: 470px;
  -webkit-box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 14px 35px rgba(0, 0, 0, 0.15);
  margin-top: 75px;
}

.map iframe {
  width: 100%;
}

@media (max-width: 991px) {

  html,
  body {
    overflow-x: hidden;
  }

  .mobile-top-fix {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .mobile-bottom-fix {
    margin-bottom: 30px;
  }

  .mobile-bottom-fix-big {
    margin-bottom: 60px;
  }

  ::-webkit-scrollbar {
    display: none;
  }

}

/* For WebKit based browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
  /* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Set the background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Set the color of the scrollbar thumb */
  border-radius: 5px;
  /* Add rounded corners to the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Set the color of the scrollbar thumb on hover */
}

.error-message {
  color: red;
  font-size: smaller;
}

.row {
  --mdb-gutter-x: revert !important;
  --bs-gutter-x: revert !important;
}

.css-58b16v {
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  opacity: 1 !important;
  -webkit-transition: font-size 0.2s, opacity 0.2s;
  transition: font-size 0.2s, opacity 0.2s;
  transition-delay: 0s;
}

.css-1gh6k7f-MuiBottomNavigationAction-label {
  font-family: "Roboto", sans-serif !important;
  font-size: 0.75rem;
  opacity: 1 !important;
  -webkit-transition: font-size 0.2s, opacity 0.2s;
  transition: font-size 0.2s, opacity 0.2s;
  transition-delay: 0s;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
  color: #ee2e24 !important;
  border: 1px solid #ee2e24 !important;
  background-color: #fff !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  font-family: "Roboto", sans-serif !important;
  color: #fff;
  background-color: #ee2e24 !important;
}

.css-1hw9j7s:hover {
  color: #ee2e24 !important;
  border: 1px solid #ee2e24 !important;
  background-color: #fff !important;
}

.css-1hw9j7s {
  font-family: "Roboto", sans-serif !important;
  color: #fff;
  background-color: #ee2e24 !important;
}

.css-nxzcop:hover {
  color: #ee2e24 !important;
  border: 1px solid #ee2e24 !important;
  background-color: #fff !important;
}

.css-nxzcop {
  font-family: "Roboto", sans-serif !important;
  color: #fff;
  background-color: #ee2e24 !important;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root:hover {
  color: #ee2e24 !important;
  border: 1px solid #ee2e24 !important;
  background-color: #fff !important;
}

.css-1vhaqj4-MuiButtonBase-root-MuiButton-root {
  font-family: "Roboto", sans-serif !important;
  color: #fff;
  background-color: #ee2e24 !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: "Roboto", sans-serif !important;
  color: #ee2e24 !important;
  background-color: #ee2e243f !important;
}

.css-1x5jdmq {
  font-family: "Roboto", sans-serif !important;
  color: #ee2e24 !important;
  background-color: #ee2e243f !important;
}



.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  background-color: #ee2e243f !important;
}

.css-fvipm8 {
  background-color: #ee2e243f !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #ee2e243f !important;
}

.css-segi59 {
  background-color: #ee2e243f !important;
}

.css-1v4ccyo {
  background-color: #ee2e243f !important;
}

.custom-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 14px !important;
}


.css-hyxlzm {
  color: #ee2e24;
}

@keyframes hotelioLoader {

  0%,
  100% {
    color: red;
  }

  50% {
    color: rgb(255, 173, 173);
  }
}

.MapViewOnClick {
  color: white !important;
  text-decoration: none !important;
  border: 1px solid transparent;
  background-color: #ee2e24;
  padding: 6px 20px;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
}

.MapViewOnClick:hover {
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15);
}

.animatedElement {
  width: 50px;
  height: 50px;
  background-color: blue;
  position: absolute;
  top: 0;
  left: 50%;
  /* Center the element horizontally */
  animation: moveDown 3s linear infinite;
}

@keyframes moveDown {
  0% {
    top: 0;
  }

  100% {
    top: 100%;
    /* Adjust the final position as needed */
  }
}

.ButtonPrimary {
  background-color: #ee2e24;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15);
  /* border-radius: 5px; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #ee2e24;
  color: white;
  user-select: none;
}

.ButtonPrimary:hover {
  background-color: #973732;
  border: 1px solid #973732;
}

.ButtonSecondary {
  background-color: #707079;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.15);
  border-radius: 100%;
  padding: 4px 12px;
  border: 1px solid #707079;
  color: white;
  user-select: none;
}

.ButtonSecondary:hover {
  background-color: #4d4d53;
  border: 1px solid #4d4d53;
}