.previousButton {
    position: absolute !important;
    z-index: 1;
    top: 50%;
    left: 15px;
}

.NextButton {
    position: absolute !important;
    z-index: 1;
    top: 50%;
    right: 15px;
}

.mapBox {
    box-shadow: 0px 0px 22px 8px rgba(213, 176, 176, 0.75);
    -webkit-box-shadow: 0px 0px 22px 8px rgba(213, 176, 176, 0.75);
    -moz-box-shadow: 0px 0px 22px 8px rgba(213, 176, 176, 0.75);
}

.Imagebox {
    overflow: hidden;
    border: 4px groove #ee2e24;
    border-radius: 20px;
}

.Imagebox img {
    width: 100%;
    height: 100%;
    transition: scale 400ms;
}

.Imagebox:hover img {
    scale: 120%;

}

@keyframes scrollX {
    0% {
        transform: translateX(100%);
        /* Start from the right */
    }

    100% {
        transform: translateX(-100%);
        /* End on the left */
    }
}