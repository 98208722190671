.MemberBackground {
  background: linear-gradient(0deg, rgba(186, 221, 231, 0.8688725490196079) 0%, rgba(255, 255, 255, 0) 100%), url(../../images/Memberbanner.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.MemberBackgroundS {
  background: linear-gradient(0deg, rgba(186, 221, 231, 0.8688725490196079) 0%, rgba(255, 255, 255, 0) 100%), url(../../images/MemberBannerS.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.MemberBackgroundL {
  background: linear-gradient(0deg, rgba(186, 221, 231, 0.8688725490196079) 0%, rgba(255, 255, 255, 0) 100%), url(https://img.freepik.com/free-vector/ombre-blue-line-patterned-background-vector_53876-173744.jpg?w=1380&t=st=1690282935~exp=1690283535~hmac=8edfdc2aa1946cd13d9dc1b49bc78912ed44dbbb0792f2cbaec886e702a8aa30);
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.Cardcolor {
  background: #e2edfe !important;
}

.CardcolorOne {
  background-color: #c5f5d8 !important;
}

.CardcolorTwo {
  background-color: #ffded5 !important;
}

.CardcolorThree {
  background-color: #f5edfc !important;
}

.CardcolorFour {
  background-color: #faf3d5 !important;
}

/* svg {
  width: 100%;
  height: 80px;
} */

svg text {
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #ee2e24;
  /* font-size: 45px; */
}

@keyframes stroke {
  0% {
    fill: rgba(72, 138, 204, 0);
    stroke: #ee2e24;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  70% {
    fill: rgba(72, 138, 204, 0);
    stroke: #ee2e24;
  }

  80% {
    fill: rgba(72, 138, 204, 0);
    stroke: #ee2e24;
    stroke-width: 3;
  }

  100% {
    fill: #ee2e24;
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}