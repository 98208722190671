.topleft {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 25%);
}
.ImgGredint {
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
.bottomleft {
  position: absolute;
  top: 66%;
  color: whitesmoke;
  padding: 5rem;
}
.centeredThisCard {
  position: absolute;
  top: 48%;
  left: 32%;
  transform: translate(-22%, -50%);
}
