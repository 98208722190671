/* MultiStepForm.css */
.multi-step-form {
  /* max-width: 1200px; */
  margin: 0 auto;
}

.stepper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 15px;
}

.step {
  flex: 1;
  text-align: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  transition: background-color 0.3s ease-in-out;
  border: 2px solid #ee2e24;
}

.step.active {
  background-color: #ee2e24;
  color: #fff;
}

.form-step {
  opacity: 0;
  transform: translateX(-20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.form-step.show {
  opacity: 1;
  transform: translateX(0);
}

.activeBorder {
  border-left: 5px solid #ee2e24;
  background-color: rgba(128, 128, 128, 0.193);
  padding: 10px 0px 10px 9px;
}

.In-activeBorder {
  padding: 10px 0px 10px 10px;
}

.credit-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.credit-card {
  width: 360px;
  height: 200px;
  perspective: 1000px;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.card-front {
  background-color: #1b2943;
  color: white;
  padding: 20px;
  transform: rotateY(0deg);
  transition: transform 0.5s;
  border-radius: 8px;
}

.card-back {
  background-color: #1b2943;
  color: white;
  padding: 20px;
  transform: rotateY(180deg);
  transition: transform 0.5s;
  border-radius: 8px;
}

.credit-card:hover .card-front {
  transform: rotateY(-180deg);
}

.credit-card:hover .card-back {
  transform: rotateY(0deg);
}

.chip {
  width: 50px;
  height: 40px;
  background: goldenrod;
  margin-bottom: 20px;
  border-radius: 5px;
}

.card-number {
  font-size: 20px;
  margin-bottom: 10px;
}

.card-holder {
  font-size: 16px;
  margin-bottom: 10px;
}

.card-expiry {
  font-size: 16px;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  border-radius: 5px;
}

/* CreditCardForm.css */

.credit-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.credit-card {
  width: 100%;
  /* Adjust width based on your design */
  max-width: 360px;
  /* Set a maximum width for the card */
  height: 200px;
  perspective: 1000px;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.step3container {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 2fr;
}

.paymentMethod {
  width: 100%;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  & button {
    gap: 10px;
    display: flex;
    align-items: center;
    & .imgDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 999px;
      padding: 10px;
      & img {
        width: 30px;
        height: 30px;
      }
    }
    & .contentDiv {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }
}

/* ... Rest of the CSS ... */

@media screen and (max-width: 768px) {
  /* Adjust styling for smaller screens */
  .credit-card {
    max-width: 100%;
    /* Allow the card to take up the full width */
  }

  .card-front,
  .card-back {
    padding: 10px;
    /* Reduce padding for smaller screens */
  }

  .chip {
    width: 40px;
    /* Adjust chip size for smaller screens */
    height: 30px;
  }

  .card-number {
    font-size: 16px;
    /* Reduce font size for smaller screens */
  }

  .card-holder,
  .card-expiry {
    font-size: 14px;
    /* Reduce font size for smaller screens */
  }

  .input-container {
    margin-top: 10px;
    /* Reduce top margin for smaller screens */
  }

  input {
    padding: 8px;
    /* Reduce input padding for smaller screens */
    font-size: 14px;
    /* Adjust input font size for smaller screens */
  }
}
