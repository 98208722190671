/* CreditCard.css */
.credit-card {
    width: 100%;
    height: 180px;
    background: linear-gradient(to bottom, #ee2e24, #2222226c);
    color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.chip {
    width: 50px;
    height: 35px;
    background: goldenrod;
    background-size: 100% 100%;
}

.rupees {
    font-size: 24px;
}

.card-bottom {
    background: #fff;
    color: black;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.card-holder {
    font-size: 16px;
    font-weight: 700;
}

.valid-thru {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.valid-date {
    margin-left: 5px;
}